<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="姓名">
              <a-input class="form-control" v-decorator="['userName']" placeholder="请输入姓名"></a-input>
            </a-form-item>
            <a-form-item label="手机号">
              <a-input class="form-control" v-decorator="['mobile']" placeholder="请输入手机号"></a-input>
            </a-form-item>
            <a-form-item label="角色">
              <a-select
                class="form-control"
                v-decorator="['role']"
                placeholder="请选择角色"
                allowClear
                :options="$collection('KX.KX_ROLE_LIST')"
              ></a-select>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="accountId"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="登陆手机号">
          <a-input
            v-decorator="[
              'mobile',
              {
                rules: [{ required: true, message: '请输入登陆手机号' }],
              },
            ]"
            placeholder="请输入登陆手机号"
            :disabled="!!modal.data.accountId"
          ></a-input>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'userName',
              {
                rules: [{ required: true, message: '请输入姓名' }],
              },
            ]"
            placeholder="请输入姓名"
          ></a-input>
        </a-form-item>

        <a-form-item label="角色">
          <a-select
            v-decorator="[
              'role',
              {
                rules: [{ required: true, message: '请选择角色' }],
              },
            ]"
            :options="$collection('KX.KX_ROLE_LIST')"
            placeholder="请选择角色"
            :disabled="!!modal.data.accountId"
          ></a-select>
        </a-form-item>
        <a-form-item label="区域">
          <a-select
            v-decorator="[
              'locationCode',
              {
                rules: [{ required: true, message: '请选择区域' }],
              },
            ]"
            :options="areaList"
            placeholder="请选择区域"
          ></a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '人员角色',
        width: '10%',
        align: 'center',
        dataIndex: 'role',
        customRender: (text, row, index) => {
          return (
            <div>
              <a-tag>{this.translateType(text, 'KX.KX_ROLE_LIST')}</a-tag>
            </div>
          )
        },
      },
      {
        title: '手机号',
        width: '10%',
        align: 'center',
        dataIndex: 'mobile',
      },
      {
        title: '人员姓名',
        width: '10%',
        align: 'center',
        dataIndex: 'userName',
      },
      {
        title: '内部人员ID',
        width: '10%',
        align: 'center',
        dataIndex: 'innerid',
      },

      {
        title: '区域',
        width: '12%',
        align: 'center',
        dataIndex: 'locationName',
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  编辑
                </a>

                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.remove(row)
                  }}
                >
                  删除
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      areaList: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/kx/usercenter/admin/user/page', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增人员'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑人员'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/kx/usercenter/admin/user/delete', {
        mobile: row.mobile,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { accountId } = this.modal.data
            const actionUrl = accountId ? '/kx/usercenter/admin/user/update' : '/kx/usercenter/admin/user/add'
            await this.$axios.post(actionUrl, {
              accountId,
              ...params,
            })
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async getAreaList() {
      const res = await this.$axios.post('/kx/fast-repair/admin/common/areaList')
      this.areaList = res.data.map(item => {
        return {
          label: item.value,
          value: item.key,
        }
      })
    },
  },
  async mounted() {
    this.getAreaList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
